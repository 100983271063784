<template>
  <img
    alt="image"
    class="border-2 br-8 my-1"
    :class="isValidCover ? 'cover' : 'pa-2'"
    height="56"
    :src="imgUrl(item)"
    width="44"
    @error="isValidCover = false"
  >
</template>

<script>
import imgUploadFailureIcon from '@/assets/icons/img_upload_failure.svg';

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isValidCover: true,
    };
  },
  methods: {
    imgUrl(item) {
      return item.isValidCover ? item.cover : imgUploadFailureIcon;
    },
  },
};
</script>
